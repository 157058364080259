import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';

// @ts-ignore
import { SettingsProvider, defaultSettings } from '@vmray/pattern-library/dist/contexts/SettingsContext';
// @ts-ignore
import type { Settings } from '@vmray/pattern-library/dist/contexts/SettingsContext';
// @ts-ignore
import App from '@vmray/pattern-library/dist/App';

// @ts-ignore
import apiConfig from './config/api';

const renderApp = async () => {
  let serverConfig: any = false;
  const serviceWorkerErrors = { browserApi: !('serviceWorker' in navigator), ssl: false, other: false };

  await axios.get('/wa-system/config')
    .then(async (response) => {
      // @ts-ignore
      if (response.data
        && response.data.config
        && response.data.config[0]
        && response.data.config[0].platform) {
        serverConfig = response.data.config;
      }
    })
    .catch(() => {
      if (process.env.NODE_ENV === 'development') {
        serverConfig = window.location.hostname === 'localhost' || window.location.hostname === 'development.mw.local.vmray.com' ? apiConfig : false;
      }
    });

  // Here we want to test the Service Worker API for showing notifications to the user if it fails.
  if (!serviceWorkerErrors.browserApi) {
    try {
      const registration = await navigator.serviceWorker.register('/nfe-static/js/sw-test.js');
      await registration.unregister();
    } catch (e: any) {
      console.error(e);

      // Does the error message include an SSL certificate related error?
      const re = new RegExp(/\bSSL\b|\bcertificate\b|\bcert\b/i);
      if (re.test(e.message)) {
        serviceWorkerErrors.ssl = true;
      } else {
        serviceWorkerErrors.other = true;
      }
    }
  }

  let settings: Settings;
  const localStorageSettings = localStorage.getItem('settings');

  const serverStateSettingsData = {
    rootPath: '/',
    // @ts-ignore
    apiConfig: serverConfig,
    logo: {
      light: 'nfe-static/images/VMRayLight.png',
      dark: 'nfe-static/images/VMRayDark.png',
    },
    serverOperationMode: serverConfig ? (serverConfig[0] && serverConfig[0].serverOperationMode ? serverConfig[0].serverOperationMode : 'maintenance') : 'maintenance',
    version: serverConfig ? (serverConfig[0].version ? serverConfig[0].version : null) : null,
  };

  // force server state of settings
  if (localStorageSettings) {
    const localStorageSettingsData = JSON.parse(localStorageSettings);

    settings = {
      ...localStorageSettingsData,
      ...serverStateSettingsData,
      serviceWorkerErrors,
      activeApiConfig: localStorageSettingsData.activeApiConfig,
    };
  } else {
    settings = {
      ...defaultSettings,
      ...serverStateSettingsData,
      serviceWorkerErrors,
      activeApiConfig: 0,
    };
  }
  localStorage.setItem('settings', JSON.stringify(settings));

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <SettingsProvider settings={settings}>
        <App startRoute="/" storyView={null} />
      </SettingsProvider>
    </React.StrictMode>,
  );
};

renderApp();
